import React, { useState } from "react";

import {
  Box,
  Typography,
  Card,
  useMediaQuery,
  useTheme,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore, ChevronRight } from "@material-ui/icons";

import PdfReader from "./PdfReader";

const PdfViewer = (props) => {
  const { PdfLinkArray, resourceName } = props;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [pdfLink, setPdfLink] = useState(PdfLinkArray[0].value);

  const handlePdfClicked = (index) => {
    setPdfLink(PdfLinkArray[index].value);
  };

  const menuList = () => {
    return PdfLinkArray.map((pdfLink, index) => {
      return (
        <Box key={index}>
          {pdfLink.key ? (
            <Box display="flex" alignItems="center">
              <ChevronRight fontSize="small" />
              <Typography
                style={{ cursor: "pointer", color: "#002060" }}
                onClick={() => handlePdfClicked(index)}
              >
                {pdfLink.key}
              </Typography>
            </Box>
          ) : (
            <Divider style={{ margin: "8px 0" }} />
          )}
        </Box>
      );
    });
  };

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      justifyContent="space-between"
    >
      <Box
        {...(!isMobileView && { position: "sticky", left: 0 })}
        {...(isMobileView && { position: "relative", top: 0 })}
        p={4}
      >
        {isMobileView ? (
          <Accordion style={{ position: "sticky", top: 0 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography> {`${resourceName} Resources`}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {menuList()}
            </AccordionDetails>
          </Accordion>
        ) : (
          <Card style={{ position: "sticky", top: 0, padding: "24px" }}>
            {menuList()}
          </Card>
        )}
      </Box>
      <Box m="auto" mt={3} overflow="hidden">
        <PdfReader pdf={`/PDFs/${pdfLink}.pdf`}></PdfReader>
      </Box>
    </Box>
  );
};

export default PdfViewer;
