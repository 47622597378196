import React from "react";

import NavLinks from "../NavLinks/NavLinks";
import NcroepLogo from "../Utils/Images/NCROEPLogo.png";
import UnccLogo from "../Utils/Images/UNCCLogo.png";
import { Mail } from "@material-ui/icons";

import {
  Box,
  Typography,
  Divider,
  Link,
  useMediaQuery,
  useTheme,
  List,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  IndividualLink: {
    color: "white",
    marginLeft: "2rem",
    textDecoration: "none",
    fontWeight: "bolder",
    "&:hover": {
      color: "royalblue",
      textDecoration: "none",
    },
  },
  IndividualLink2: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "royalblue",
      textDecoration: "none",
    },
  },
  SponsorImage: {
    maxHeight: "150px",
    margin: "10px",
    marginTop: "20px",
    "&:hover": {
      maxHeight: "160px",
    },
  },
});

const Footer = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Box
      style={{
        background: "#00acc1",
        width: "100%",
        position: "static",
        bottom: "0px",
        display: "flex",
        flexDirection: isMobileView ? "column" : "row",
        justifyContent: "space-between",
        color: "white",
      }}
    >
      {/* Links */}
      <Box m={2}>
        <Typography variant="h6">Links:</Typography>
        <Divider style={{ backgroundColor: "white" }} />
        <List>
          <NavLinks />
          {/* Additional Links */}
          <Typography>
            <Link
              href="https://github.com/ME-Grid"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.IndividualLink}
            >
              {/* Download Manual
            </Link>
          </Typography>
          <Typography>
            <Link
              href="https://github.com/ME-Grid"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.IndividualLink}
            > */}
              GitHub Link
            </Link>
          </Typography>
        </List>
      </Box>

      <Box m={2} minHeight="180px">
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
        >
          <Link
            href="https://www.coastalstudiesinstitute.org/ncroep/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={NcroepLogo}
              alt="NCROEP"
              className={classes.SponsorImage}
            />
          </Link>

          <Link
            href="https://www.uncc.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={UnccLogo} alt="UNCC" className={classes.SponsorImage} />
          </Link>
        </Box>
        <Typography>
          This project is sponsored by North Carolina Renewable Ocean Energy
          Program.
        </Typography>
      </Box>

      <Box m={2}>
        <Typography variant="h6">Contact Us:</Typography>
        <Divider style={{ backgroundColor: "white" }} />
        <Box display="flex" mt={3}>
          <Box mr={2}>
            <Mail/>
          </Box>
          <Typography>
            <Link
                href="mailto:lbai4@uncc.edu"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.IndividualLink2}
              >Dr. Linquan Bai
            </Link>
          </Typography>
        </Box>
        <Box display="flex" mt={3}>
          <Box mr={2}>
            <Mail/>
          </Box>
          <Typography>
            <Link
                href="mailto:contactmegrid@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.IndividualLink2}
              >contactmegrid@gmail.com
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
