import React from "react";

import LearnJson from "../../Utils/Learn/Learn.json";

import PdfViewer from "../../Utils/PDFViewer/PdfViewer";

const Learn = () => {
  const learnJsonToArray = LearnJson.slice();

  return <PdfViewer PdfLinkArray={learnJsonToArray} resourceName="Support" />;
};

export default Learn;
