import React from "react";

import SupportJson from "../../Utils/Support/Support.json";

import PdfViewer from "../../Utils/PDFViewer/PdfViewer";

const Support = () => {
  const supportJsonToArray = SupportJson.slice();

  return <PdfViewer PdfLinkArray={supportJsonToArray} resourceName="Support" />;
};

export default Support;
