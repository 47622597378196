import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Slide1 from "../../Utils/Images/GalleryPics/Slide1.JPG";
import Slide2 from "../../Utils/Images/GalleryPics/Slide2.JPG";
import Slide3 from "../../Utils/Images/GalleryPics/Slide3.JPG";
import Slide4 from "../../Utils/Images/GalleryPics/Slide4.JPG";
import Slide5 from "../../Utils/Images/GalleryPics/Slide5.JPG";
import Slide6 from "../../Utils/Images/GalleryPics/Slide6.JPG";
import Slide7 from "../../Utils/Images/GalleryPics/Slide7.JPG";
import Slide8 from "../../Utils/Images/GalleryPics/Slide8.JPG";
import Slide9 from "../../Utils/Images/GalleryPics/Slide9.JPG";

const MeGridFeatures = () => {
  return (
    <Carousel autoPlay infiniteLoop showThumbs={false}>
      <div>
        <img alt="" src={Slide1} />
        {/* <p className="legend">Legend 1</p> */}
      </div>
      <div>
        <img alt="" src={Slide2} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img alt="" src={Slide3} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img alt="" src={Slide4} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img alt="" src={Slide5} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img alt="" src={Slide6} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img alt="" src={Slide7} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img alt="" src={Slide8} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img alt="" src={Slide9} />
        {/* <p className="legend">Legend 2</p> */}
      </div>
    </Carousel>
  );
};

export default MeGridFeatures;
