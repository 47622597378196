import React from "react";

import { Card } from "@material-ui/core";

const InfoCard = (props) => {
  return (
    <Card
      style={{
        boxSizing: "border-box",
        width: "100%",
        margin: "auto",
        marginTop: "16px",
        padding: "16px",
        // color: "darkslategray",
        color: "#002060",
      }}
    >
      {props.children}
    </Card>
  );
};

export default InfoCard;
