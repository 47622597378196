import React from "react";
import { withStyles, createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import { Check, Clear, GetApp, ExpandMore } from "@material-ui/icons";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      textAlign: "center",
      backgroundColor: "#00acc1",
      color: "white",
      fontSize: "large",
      fontWeight: "bolder",
      [theme.breakpoints.down("sm")]: {
        fontSize: "small",
        fontWeight: "normal",
      },
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "royal blue",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {},
  tableContainer: {
    width: "80vw",
  },
  downLoadButton: {
    backgroundColor: "#F3A712",
    color: "white",
    borderRadius: "5px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#f8c45d",
    },
  },
});

const Rows = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const createData = (key, feature, meGridLite, meGridAdvanced) => {
    return { key, feature, meGridLite, meGridAdvanced };
  };

  const check = <Check style={{ color: "#00acc1" }} />;
  const clear = <Clear style={{ color: "#00acc1" }} />;

  const rows = [
    createData(
      "DownloadLink",
      <Typography variant="subtitle1">Download Link (ZIP)</Typography>,
      <Button
        className={classes.downLoadButton}
        href="https://github.com/ME-Grid/ME-Grid-Lite/archive/refs/tags/V1.0.zip"
        target="_blank"
        rel="noopener noreferrer"
        {...(!isMobileView && { startIcon: <GetApp /> })}
      >
        {isMobileView ? <GetApp /> : "Zip"}
      </Button>,
      <Button
        className={classes.downLoadButton}
        href="https://github.com/ME-Grid/ME-Grid-Lite/archive/refs/tags/V1.0.zip"
        target="_blank"
        rel="noopener noreferrer"
        {...(!isMobileView && { startIcon: <GetApp /> })}
      >
        {isMobileView ? <GetApp /> : "Zip"}
      </Button>
    ),
    createData(
      "DownloadLink2",
      <Typography variant="subtitle1">Download Link (TAR.GZ)</Typography>,
      <Button
        className={classes.downLoadButton}
        href="https://github.com/ME-Grid/ME-Grid-Lite/archive/refs/tags/V1.0.tar.gz"
        target="_blank"
        rel="noopener noreferrer"
        {...(!isMobileView && { startIcon: <GetApp /> })}
      >
        {isMobileView ? <GetApp /> : "tar.gz"}
      </Button>,
      <Button
        className={classes.downLoadButton}
        href="hhttps://github.com/ME-Grid/ME-Grid-Lite/archive/refs/tags/V1.0.tar.gz"
        target="_blank"
        rel="noopener noreferrer"
        {...(!isMobileView && { startIcon: <GetApp /> })}
      >
        {isMobileView ? <GetApp /> : "tar.gz"}
      </Button>
    ),
    createData(
      "Version",
      <Typography variant="subtitle1">Version</Typography>,
      "V 1.0",
      "V 1.0"
    ),
    createData(
      "FileType",
      <Typography variant="subtitle1">File Type</Typography>,
      ".exe",
      "MATLAB Application (Will be released soon)"
    ),
    createData(
      "MREData",
      <>
        <Typography variant="subtitle1">MRE Data</Typography>
        <Typography variant="subtitle2">- Meterological Data</Typography>
        <Typography variant="subtitle2">- MRE Device Data</Typography>
        <Typography variant="subtitle2">- MRE Device Costs</Typography>
      </>,
      check,
      check
    ),
    createData(
      "MREDataAnalysis",
      <>
        <Typography variant="subtitle1">MRE Data Analysis</Typography>
        <Typography variant="subtitle2">- Location Data</Typography>
        <Typography variant="subtitle2">
          - Patterns and variability analysis
        </Typography>
        <Typography variant="subtitle2">- Device cost analysis</Typography>
      </>,
      check,
      check
    ),
    createData(
      "MREEconomicAnalysis",
      <Typography variant="subtitle1">MRE Economic Analysis</Typography>,
      check,
      check
    ),
    createData(
      "DailyPowerAnalysis",
      <Typography variant="subtitle1">Daily Power Analysis</Typography>,
      check,
      check
    ),
    createData(
      "GridAnalysis",
      <Typography variant="subtitle1">Grid Analysis</Typography>,
      clear,
      check
    ),
    createData(
      "StochasticAnalysis",
      <Typography variant="subtitle1">Stochastic Analysis</Typography>,
      clear,
      check
    ),
    createData(
      "ResilienceAnalysis",
      <Typography variant="subtitle1">Resilience Analysis</Typography>,
      clear,
      check
    ),
    createData(
      "Reports",
      <Typography variant="subtitle1">Reports</Typography>,
      check,
      check
    ),
  ];

  return rows;
};

const Download = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const rows = Rows();

  return (
    <>
      <Box width={isMobileView ? "95%" : "50% "} m="auto" mb={2}>
        {/* <Typography variant="subtitle1" style={{marginBottom:"20px"}}>Text to be added in future</Typography> */}
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Features</StyledTableCell>
                <StyledTableCell>ME-Grid-Lite</StyledTableCell>
                <StyledTableCell>ME-Grid-Advanced</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.key}>
                  <StyledTableCell
                    style={{ backgroundColor: "#00acc1", color: "white" }}
                  >
                    {row.feature}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.meGridLite}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.meGridAdvanced}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Accordion
        style={{
          width: isMobileView ? "95%" : "50%",
          margin: "auto",
          marginBottom: "24px",
        }}
      >
        
        {/* Expandable menu to list all the previous versions */}
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Previous Versions</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <Typography>
            - Version{"V0.0 "}
            <a
              href="https://github.com/ME-Grid/Trial/archive/refs/tags/V0.0.zip"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "#00acc1",
              }}
            >
              ME_Grid_Lite
            </a>
          </Typography>
          <Typography>Date: 6-Jun-2021 : Trial Check</Typography>
          <Divider />
        </AccordionDetails>
{/* Add more versions */}
        {/* <AccordionDetails style={{ flexDirection: "column" }}>
          <Typography>
            - Version{" "}
            <a
              href="https://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "#00acc1",
              }}
            >
              ME_Grid_Lite
            </a>
          </Typography>
          <Typography>Date: 6-Jun-2021 : Trial Check</Typography>
        </AccordionDetails> */}
      </Accordion>
    </>
  );
};

export default Download;
