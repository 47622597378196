import React from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";

import InfoCard from "../InfoCard/InfoCard";
import Team from "./Team/Team";

const useStyles = makeStyles({
  Topic: {
    backgroundColor: "#eceef8",
    marginTop: "-16px",
    marginLeft: "-16px",
    marginRight: "-16px",
    padding: "4px 16px",
  },
});

const About = () => {
  const classes = useStyles();
  const whatIsMeGrid = (
    <>
    <Box className={classes.Topic}>
      <Typography variant="h6">Why ME-Grid?</Typography></Box>
      <Typography align="justify">
        The utilization and accommodation of Marine Renewable Energy (MRE) rely
        on the transmission and distribution power grids. When planning for
        marine power integration, the coordination between marine power
        production and the power grid operation must be considered. Without
        careful planning, the power grid operation may not be able to
        accommodate marine energy and may even restrict the delivery of MRE to
        power consumers. The location and connection point of marine renewable
        power generation have a great impact on the power grid in terms of
        resilience, stability and economics. To achieve efficient transmission
        and delivery of marine energy, various issues need to be addressed in
        the optimal integration and planning of MRE in the power grid such as
        uncertainty and variability patterns, economics of MRE devices, MRE
        integration points, and impact of extreme weather scenarios on the
        resilience of power grids.
      </Typography>
      <Typography align="justify" style={{ marginTop: "20px" }}>
        ME-Grid is an open source tool designed and developed for teaching and
        research purposes. This tool can be used to model the uncertainty and
        variable patterns at a selected site, perform an economic analysis for
        an MRE device, and use the numerical results from the analysis to
        integrate the MRE in the power grid.
      </Typography>
      <Typography align="justify" style={{ marginTop: "20px" }}>
        The ME-Grid tool can be used to identify scenarios to perform a
        stochastic optimization analysis for optimal scheduling and economic
        dispatch of the power grid with MRE to minimize total operation cost and
        enhance the power grid resilience. The tool can also be used to perform
        cost-benefit and resilience analysis in the extreme event scenarios such
        as hurricanes where power disruption can occur due to events such as
        damaged transmission or distribution lines.
      </Typography>
    </>
  );

  const acknowledgments = (
    <>
    <Box className={classes.Topic}>
      <Typography variant="h6">Acknowledgments</Typography></Box>
      <Typography>{"<<TBD>>"}</Typography>
    </>
  );

  return (
    <Box width="80vw" m="auto" mb={3}>
      {[whatIsMeGrid, <Team />, acknowledgments].map((child, index) => {
        return <InfoCard key={index} children={child} />;
      })}
    </Box>
  );
};

export default About;
