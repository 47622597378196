import React from "react";

import {
  Box,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import InfoCard from "../InfoCard/InfoCard";
import MeGridFeatures from "./meGridFeatures";
import PastWebinars from "./PastWebinars";
import NewsJson from "../../Utils/News/News.json";

const useStyles = makeStyles({
  Topic: {
    backgroundColor: "#eceef8",
    marginTop: "-16px",
    marginLeft: "-16px",
    marginRight: "-16px",
    padding: "4px 16px",
  },
});

const Home = () => {
  // hooks
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const NewsJsonToArray = NewsJson.slice();

  const meGridDescription = (
    <InfoCard>
      <Box className={classes.Topic}>
        <Typography variant="h6">ME-Grid</Typography>
      </Box>
      <Typography align="justify">
      ME-Grid is a tool for assessing the economics and resilience of Marine Renewable generation into the power grid. This is an open-source tool used to promote research and education in Marine Renewable energy.
      </Typography>
    </InfoCard>
  );
  const currentVersion = (
    <InfoCard>
      <Box className={classes.Topic}>
        <Typography variant="h6">Latest Version</Typography>
      </Box>
      <Typography align="justify">
        A New verion is currently being developed for MATLAB and Python. The New enhansed version will be posted very shortly.
      </Typography>
      <Typography align="justify">
        ME-Grid-Lite V1.0 Date: Aug 6, 2021 : Version 1.0
      </Typography>
      <Typography align="justify">
        ME-Grid-Advanced V1.0 Date: Aug 6, 2021 : Version 1.0
      </Typography>
    </InfoCard>
  );

  const meGridCaraousel = (
    <InfoCard>
      <Box className={classes.Topic}>
        <Typography variant="h6">Features</Typography>
      </Box>
      <MeGridFeatures />
    </InfoCard>
  );
  const upcomingWebinars = (
    <InfoCard>
      <Box className={classes.Topic}>
        <Typography variant="h6">Upcoming Webinars</Typography>
      </Box>
      <Typography align="justify">NA</Typography>
    </InfoCard>
  );
  const news = (
    <InfoCard>
      <Box className={classes.Topic}>
        <Typography variant="h6">News</Typography>
      </Box>
      {NewsJsonToArray.map((newsItem, index) => {
        return (
          <Box key={index}>
            {newsItem.heading ? (
              <>
                <Typography variant="h6">
                  <a
                    href={newsItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    {newsItem.heading}
                  </a>
                </Typography>

                <Typography style={{ color: "#757C88" }}>
                  {newsItem.source}
                </Typography>
              </>
            ) : (
              <Divider style={{ margin: "8px 0" }} />
            )}
          </Box>
        );
      })}
    </InfoCard>
  );
  const pastWebCarousel = (
    <InfoCard>
      <Box className={classes.Topic}>
        <Typography variant="h6">Past Webinars</Typography>
      </Box>
      <Box width="60%" m="auto" mb={3}>
        <PastWebinars />
      </Box>
    </InfoCard>
  );

  const collaborations = (
    <Box
      width={isMobileView ? "80vw" : "60vw"}
      ml={isMobileView ? "2.5vw" : 0}
      mb={3}
    >
      {/* <InfoCard>
        <Box className={classes.Topic}>
          <Typography variant="h6">Collaborations</Typography>
        </Box>

        {"<<TBD>>"}
      </InfoCard> */}
    </Box>
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        justifyContent="space-between"
      >
        <Box width={isMobileView ? "80vw" : "60vw"} ml={isMobileView ? "10vw" : "2.5vw"}>
          {meGridDescription}
          {meGridCaraousel}
          {!isMobileView && collaborations}
        </Box>
        <Box
          width={isMobileView ? "80vw" : "30vw"}
          mr={"2.5vw"}
          ml={isMobileView ? "10vw":"auto"}
          mb={isMobileView ? 0 : 3}
        >
          {currentVersion}
          {upcomingWebinars}
          {news}
          {pastWebCarousel}
        </Box>
        {isMobileView && collaborations}
      </Box>
    </>
  );
};

export default Home;
