import React from "react";
import { NavLink } from "react-router-dom";

import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  IndividualLink: {
    color: "white",
    margin: "2rem",
    textDecoration: "none",
    fontWeight: "bolder",
    "&:hover": {
      color: "royalblue",
      textDecoration: "none",
    },
  },
  Active: {
    borderBottom: "2px solid white",
  },
});

const NavigationItem = (props) => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle1" fontWeight="600px">
      <NavLink
        to={props.link}
        exact={props.exact}
        className={classes.IndividualLink}
        activeClassName={classes.Active}
      >
        {props.children}
      </NavLink>
    </Typography>
  );
};

const NavigationItems = () => {
  return (
    <>
      <NavigationItem link="/" exact>
        Home
      </NavigationItem>
      <NavigationItem link="/support" exact>
        Support
      </NavigationItem>
      <NavigationItem link="/learn" exact>
        Learn
      </NavigationItem>
      <NavigationItem link="/about" exact>
        About
      </NavigationItem>
      <NavigationItem link="/download" exact>
        Download
      </NavigationItem>
    </>
  );
};

export default NavigationItems;
