import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import MainHeader from "./Header/MainHeader";
import Footer from "./Footer/Footer";
import Download from "./Components/Download/Download";
import Support from "./Components/Support/Support";
import Learn from "./Components/Learn/Learn";
import About from "./Components/About/About";
import Home from "./Components/Home/Home";
import { Box } from "@material-ui/core";

const App = () => {
  return (
    <BrowserRouter>
      <Box>
        <Box>
          <MainHeader />
          <Box>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/learn" component={Learn} />
              <Route exact path="/about" component={About} />
              <Route exact path="/download" component={Download} />
            </Switch>
          </Box>
        </Box>
        <Footer />
      </Box>
    </BrowserRouter>
  );
};

export default App;
