import React from "react";

import { Card, Typography, Box, Link, Tooltip, makeStyles } from "@material-ui/core";
import { LinkedIn } from "@material-ui/icons";

import TeamMembers from "../../../Utils/TeamMembers/TeamMembers.json";
const useStyles = makeStyles({
  Topic: {
    backgroundColor: "#eceef8",
    marginTop: "-16px",
    marginLeft: "-16px",
    marginRight: "-16px",
    padding: "4px 16px",
  },
});
const Team = (props) => {
  const classes = useStyles();
  return (
    <>
    <Box className={classes.Topic}>
      <Typography variant="h6">
        Team
      </Typography></Box>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        {TeamMembers.map((member) => {
          return (
            <Card
              key={member.profilePictureId}
              style={{
                boxSizing: "border-box",
                padding: "16px",
                // color: "darkslategray",
                color: "#002060",
                backgroundColor: "#f2f7f7",
                maxWidth: "30rem",
                margin: "20px auto",
              }}
            >
              <Box>
                <Box mb={2}>
                  <img
                    width="100%"
                    src={`/TeamProfilePictures/${member.profilePictureId}.jpg`}
                    alt="member"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    {member.name}
                  </Typography>
                  <Typography>{member.designation}</Typography>
                  <Typography>{member.affiliation}</Typography>

                  <Tooltip title={member.LinkedInProfile}>
                    <Link
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      href={member.LinkedInProfile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedIn />
                    </Link>
                  </Tooltip>
                </Box>
              </Box>
            </Card>
          );
        })}
      </Box>
    </>
  );
};

export default Team;
