import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import RCCC from "../../Utils/Images/PastWebinars/RCCC.JPG";
import MCHS from "../../Utils/Images/PastWebinars/MCHS.JPG";
import NCAT from "../../Utils/Images/PastWebinars/NCAT.JPG";

const PastWebinars = () => {
  return (
    <Carousel autoPlay infiniteLoop showThumbs={false}>
      <div>
        <img alt="" src={NCAT} />
      </div>
      <div>
        <img alt="" src={MCHS} />
      </div>
      <div>
        <img alt="" src={RCCC} />
      </div>
    </Carousel>
  );
};

export default PastWebinars;
