import React, { useState } from "react";

import Classes from "./MainHeader.module.css";
import megridLogo from "../Utils/Images/ME-Grid.png";
import NavLinks from "../NavLinks/NavLinks";

const MainHeader = () => {
  const [sideNavMenuShow, setSideNavMenuShow] = useState(false);

  const handleHamburgerMenuClick = () => {
    setSideNavMenuShow(!sideNavMenuShow);
  };
  return (
    <>
      <div className={Classes.Header}>
        {/* <!--Content before waves--> */}
        <div className={[Classes.InnerHeader, Classes.Flex].join(" ")}>
          <img
            src={megridLogo}
            alt="ME-Grid"
            height="67px"
            width="95px"
            style={{ marginBottom: "-8px", marginTop: "4px" }}
          />
          <>
            {/* Render on desktop view */}
            <ul className={Classes.NavigationItemsDesktop}>
              <NavLinks />
            </ul>

            {/* Render on mobile view */}
            <div
              className={Classes.HamburgerMenu}
              onClick={handleHamburgerMenuClick}
            >
              <svg viewBox="0 0 100 80" width="40" height="40" color="white">
                <rect width="100" height="10" fill="white"></rect>
                <rect y="30" width="100" height="10" fill="white"></rect>
                <rect y="60" width="100" height="10" fill="white"></rect>
              </svg>
              <div
                className={Classes.NavigationItemsMobile}
                style={{ display: sideNavMenuShow ? "flex" : "none" }}
              >
                <NavLinks />
              </div>
            </div>
          </>
        </div>

        {/* <!--Waves Container--> */}
        <div>
          <svg
            className={Classes.Waves}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className={Classes.Parallax}>
              <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255)" />
            </g>
          </svg>
        </div>
        {/* <!--Waves end--> */}
      </div>
      {/* <!--Header ends--> */}
    </>
  );
};

export default MainHeader;
